<template>
  <div id="preview" class="vertical">
    <div class="inner">
      <div class="image fit">
        <img src="images/frank.jpg" alt="Frank of Shutterwax" />
      </div>
      <div class="content">
        <header>
          <h2>Frank Diorio</h2>
        </header>
        <p>Drums</p>
      </div>
    </div>
    <router-link :to="{ name: 'Mark', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Hurricane', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'FrankPage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
